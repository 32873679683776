:root {
  --frame-hover-color: lightgray;
}

.fancy-hover {
  border-radius: 4px;
  /* border: 1px solid #333; */
  font-family: "Cinzel", serif;
  font-size: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  transition: 1s;
}

.fancy-hover::before,
.fancy-hover::after {
  position: absolute;
  /* background: #eee; */
  z-index: -1;
  transition: 0.3s;
  content: "";
}

.fancy-hover::before {
  top: -1px;
  bottom: -1px;
  left: 20px;
  right: 20px;
}

.fancy-hover::after {
  left: -1px;
  right: -1px;
  top: 20px;
  bottom: 20px;
}

.fancy-hover:hover::before {
  /* background-image: linear-gradient(var(--frame-hover-color), var(--frame-hover-color)); */
  right: 50%;
  left: 50%;
  /* background: #fff; */
}

.fancy-hover:hover::after {
  /* background-image: linear-gradient(var(--frame-hover-color), var(--frame-hover-color)); */
  top: 50%;
  bottom: 50%;
  /* background: #fff; */
}

.fancy-hover:hover {
  /* background-image: linear-gradient(var(--frame-hover-color), var(--frame-hover-color)); */
  /* background: #fff; */
  transition: 1s;
}
