html,
body {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
  min-height: 100%;
}

.stats-iframe {
  transition: height 0.5s;
}

/* select,   textarea,   input[type="text"],   input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] { font-size: 16px; } */
